import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
// Hooks
import ProDataGrid from "components/proDataGrid/ProDataGrid";
import { Card, Grid } from "@mui/material";
import { useAPI, useAuth, usePopups } from "hooks";
import * as MESSAGES from "common/constants/messages";
import dayjs, { Dayjs } from "dayjs";
import {
  EVENTUAL_CONSISTENCY_TIMEOUT,
  TYPE_ERROR,
} from "common/constants/common";
import {
  mapCSVData,
  mapTableData,
  tableColumnList,
} from "utils/commonFunctions";
import {
  ColumnData,
  CSVData,
  CSVHeader,
  FeatureFlags,
  FilterData,
  TypeClaim,
} from "common/types/commonTypes";
import {
  Claim,
  ModelClaimFilterInputNew as _ModelClaimFilterInputNew,
  OrgUserResponse,
  ClaimStatus,
} from "@s12solutions/types";
import { INITIAL_FEATURE_FLAG_VALUES } from "common/constants/initialValues";
import { DateRange } from "@mui/x-date-pickers-pro";
import { isEmpty } from "lodash";
import { Methods } from "api";
import PaidDetails from "pages/Paid/PaidDetails";
import { useLocation, useNavigate } from "react-router-dom";

type ModelClaimFilterInputNew = Omit<_ModelClaimFilterInputNew, "status"> & {
  status: ClaimStatus | "archived";
};

const Archived: FC = () => {
  const { user } = useAuth();
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [rowData, setRowData] = useState<TypeClaim[]>([]);
  const [clickedRowId, setClickedRowId] = useState<string>("");
  const [searchData, setSearchData] = useState<FilterData | null>(null);
  const [ccgFilterData, setCcgFilterData] = useState<ColumnData | null>(null);
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([
    dayjs().subtract(3, "years"),
    dayjs(),
  ]);
  const [csvData, setCsvData] = useState<[CSVHeader[], CSVData[]]>([[], []]);
  const [selectedCsvData, setSelectedCsvData] = useState<
    [CSVHeader[], CSVData[]]
  >([[], []]);
  const { handleBannerMessage } = usePopups();

  const navigate = useNavigate();
  const location = useLocation();

  // Queries
  const {
    data: archivedClaimData,
    loading: archivedClaimLoading,
    error: archivedClaimError,
    trigger: getPaidClaim,
  } = useAPI<
    Claim[],
    {
      filter: ModelClaimFilterInputNew;
    }
  >({
    method: Methods.GET,
    fieldName: "listClaims",
    manual: true,
  });

  const {
    data: dateRangeData,
    loading: dateRangeDataLoading,
    error: dateRangeDataError,
    trigger: getDateRangeData,
  } = useAPI<
    Claim[],
    {
      filter: ModelClaimFilterInputNew;
    }
  >({
    method: Methods.GET,
    fieldName: "listClaims",
    manual: true,
    args: {
      filter: {
        status: "archived",
        receivedDateFrom:
          dateRange && dateRange[0] ? dateRange[0].format("YYYY-MM-DD") : "",
        receivedDateTo:
          dateRange && dateRange[1] ? dateRange[1].format("YYYY-MM-DD") : "",
      },
    },
  });

  const {
    data: ccgData,
    loading: ccgLoading,
    error: ccgError,
    trigger: getCcg,
  } = useAPI<
    OrgUserResponse[],
    {
      id: string;
    }
  >({
    method: Methods.GET,
    fieldName: "getClaimUserOrgs",
    manual: true,
  });

  const ccgList = useMemo(
    () =>
      ccgData?.map((item) => {
        return {
          key: item.id,
          value: item.name,
        };
      }) ?? [],
    [ccgData]
  );

  const getClaims = useCallback(() => {
    if (!user) {
      handleBannerMessage(TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
      return;
    }
    return getPaidClaim({
      filter:
        searchData && ccgFilterData
          ? {
              status: "archived",
              searchValue: searchData.searchString,
              claimOrganisationId: ccgFilterData.key,
            }
          : searchData
          ? {
              status: "archived",
              searchValue: searchData.searchString,
            }
          : ccgFilterData
          ? {
              status: "archived",
              claimOrganisationId: ccgFilterData.key,
            }
          : {
              status: "archived",
            },
    });
  }, [ccgFilterData, getPaidClaim, handleBannerMessage, searchData, user]);

  useEffect(() => {
    if (archivedClaimData && !archivedClaimLoading) {
      setRowData(archivedClaimData?.map(mapTableData));
    } else {
      setRowData([]);
    }
    return () => {
      setRowData([]);
    };
  }, [archivedClaimData, archivedClaimLoading]);

  // Get paid Claim
  useEffect(() => {
    getClaims();
  }, [getClaims]);

  useEffect(() => {
    if (user) {
      getCcg({
        id: user.username,
      });
    }
  }, [getCcg, user]);

  useEffect(() => {
    if (dateRange) {
      getDateRangeData();
    }
  }, [dateRange, getDateRangeData]);

  useEffect(() => {
    if (archivedClaimError || ccgError || dateRangeDataError) {
      handleBannerMessage(TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
    }
  }, [ccgError, dateRangeDataError, handleBannerMessage, archivedClaimError]);

  const ccg = useMemo(() => ccgData ?? [], [ccgData]);
  const featuresFromAllOrgs = useMemo(
    () =>
      ccg
        .map(
          (o) =>
            JSON.parse(
              o?.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
            ) as FeatureFlags
        )
        .reduce(
          (acc, curr) => ({
            ...acc,
            ...Object.entries(curr)
              .filter(([_, value]) => !!value)
              .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {}),
          }),
          {} as FeatureFlags
        ),
    [ccg]
  );

  const currentOrg = useMemo(
    () =>
      ccgFilterData && ccgFilterData?.length > 0
        ? ccg.find((o) => o.id === ccgFilterData?.key)
        : ({} as OrgUserResponse),
    [ccg, ccgFilterData]
  );
  const featuresFromCurrentOrg = useMemo(
    () =>
      !isEmpty(
        JSON.parse(
          currentOrg?.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
        ) as FeatureFlags
      )
        ? (JSON.parse(
            currentOrg?.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
          ) as FeatureFlags)
        : featuresFromAllOrgs,
    [currentOrg?.featureFlags, featuresFromAllOrgs]
  );

  useEffect(() => {
    if (dateRangeData) {
      setCsvData(mapCSVData(dateRangeData));
    }
  }, [dateRangeData]);

  const setSearchDataValue = useCallback(
    (val: FilterData) => {
      setSearchData(val);
    },
    [setSearchData]
  );

  return (
    <>
      {showDetails && (
        <PaidDetails
          rowId={clickedRowId}
          goBack={(from: "claimUpdating" | "backButton" | "sideMenuClick") => {
            switch (from) {
              case "sideMenuClick":
              case "backButton":
                getClaims();
                break;
              case "claimUpdating":
                setTimeout(() => {
                  getClaims();
                }, EVENTUAL_CONSISTENCY_TIMEOUT);
                break;
              default:
                break;
            }

            setShowDetails(false);
          }}
        />
      )}
      {!showDetails && (
        <Grid
          m={1}
          p={2}
          component={Card}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderRadius: 1,
            minHeight: window.innerHeight - 85,
          }}
        >
          <Grid container m={0}>
            <Grid container spacing={3} sx={{ height: "fit-content" }}>
              <Grid item xs={12}>
                <ProDataGrid
                  title="Archived"
                  subtitle="Claims that were paid more than 1 year ago"
                  rows={rowData}
                  columns={tableColumnList(
                    featuresFromAllOrgs,
                    featuresFromCurrentOrg,
                    ccgFilterData && ccgFilterData?.length > 0
                      ? ccgFilterData?.key
                      : undefined,
                    "paid"
                  )}
                  ccgList={ccgList}
                  filterData={searchData}
                  filterCcgData={ccgFilterData}
                  dataError={!archivedClaimData && !archivedClaimLoading}
                  csvData={csvData}
                  selectedCsvData={selectedCsvData}
                  dateRangeLoading={dateRangeDataLoading}
                  button={{}}
                  dateRange={(val) => {
                    setDateRange(val);
                  }}
                  loading={archivedClaimLoading}
                  ccgLoading={ccgLoading}
                  refetchData={() => {
                    setSearchData(null);
                  }}
                  debouncedSearchString={setSearchDataValue}
                  selectedRows={(rowIds) => {
                    if (archivedClaimData) {
                      setSelectedCsvData(mapCSVData(archivedClaimData, rowIds));
                    }
                  }}
                  ccgValue={(val) => {
                    setCcgFilterData(val);
                  }}
                  onClickRowData={(event) => {
                    setClickedRowId(event.id);
                    setShowDetails(true);
                    navigate(location.pathname, {
                      state: { isSameMenu: false, isInDetailsPage: true },
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default memo(Archived);
