import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
// Hooks
import ProDataGrid from "components/proDataGrid/ProDataGrid";
import { Card, Grid } from "@mui/material";
import ApprovedDetails from "./ApprovedDetails";
import { useAPI, useAuth, useLoading, usePopups } from "hooks";
import * as MESSAGES from "common/constants/messages";
import dayjs, { Dayjs } from "dayjs";
import {
  EVENTUAL_CONSISTENCY_TIMEOUT,
  TYPE_ERROR,
} from "common/constants/common";
import PaidIcon from "@mui/icons-material/CreditScore";
import {
  mapCSVData,
  mapTableData,
  tableColumnList,
} from "utils/commonFunctions";
import {
  ColumnData,
  CSVData,
  CSVHeader,
  FeatureFlags,
  FilterData,
  TypeClaim,
} from "common/types/commonTypes";
import {
  CcgUpdateClaimInput,
  Claim,
  ModelClaimFilterInputNew,
  OrgUserResponse,
  User,
} from "@s12solutions/types";
import { INITIAL_FEATURE_FLAG_VALUES } from "common/constants/initialValues";
import { CLAIM_STATUS } from "common/constants/options";
import { DateRange } from "@mui/x-date-pickers-pro";
import { isEmpty } from "lodash";
import { Methods } from "api";
import { CLAIM_NOTES } from "common/constants/notes";
import { useLocation, useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { LOADING_STATES } from "common/types/loading";

const Approved: FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [rowData, setRowData] = useState<TypeClaim[]>([]);
  const [clickedRowId, setClickedRowId] = useState<string>("");
  const [searchData, setSearchData] = useState<FilterData | null>(null);
  const [ccgFilterData, setCcgFilterData] = useState<ColumnData | null>(null);
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([
    dayjs().subtract(1, "day"),
    dayjs(),
  ]);
  const [csvData, setCsvData] = useState<[CSVHeader[], CSVData[]]>([[], []]);
  const [selectedCsvData, setSelectedCsvData] = useState<
    [CSVHeader[], CSVData[]]
  >([[], []]);
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const { handleConfirmation, handleBannerMessage } = usePopups();

  const { loadingState, setLoadingState, finishLoading } = useLoading();

  // Queries
  const {
    data: approvedClaimData,
    loading: approvedClaimLoading,
    error: approvedClaimError,
    trigger: getApprovedClaim,
  } = useAPI<
    Claim[],
    {
      filter: ModelClaimFilterInputNew;
    }
  >({
    method: Methods.GET,
    fieldName: "listClaims",
    manual: true,
  });

  const {
    data: dateRangeData,
    loading: dateRangeDataLoading,
    error: dateRangeDataError,
    trigger: getDateRangeData,
  } = useAPI<
    Claim[],
    {
      filter: ModelClaimFilterInputNew;
    }
  >({
    method: Methods.GET,
    fieldName: "listClaims",
    manual: true,
    args: {
      filter: {
        status: CLAIM_STATUS.approved,
        receivedDateFrom:
          dateRange && dateRange[0] ? dateRange[0].format("YYYY-MM-DD") : "",
        receivedDateTo:
          dateRange && dateRange[1] ? dateRange[1].format("YYYY-MM-DD") : "",
      },
    },
  });

  const {
    data: ccgData,
    loading: ccgLoading,
    error: ccgError,
    trigger: getCcg,
  } = useAPI<
    OrgUserResponse[],
    {
      id: string;
    }
  >({
    method: Methods.GET,
    fieldName: "getClaimUserOrgs",
    manual: true,
  });

  const {
    loading: updateClaimLoading,
    error: updateClaimError,
    trigger: updateClaim,
  } = useAPI<
    Claim[],
    {
      input: CcgUpdateClaimInput;
    }
  >({
    method: Methods.PUT,
    fieldName: "updateClaim",
  });

  const {
    data: claimUserData,
    error: claimUserDataError,
    trigger: getUserData,
  } = useAPI<
    User,
    {
      id: string;
    }
  >({
    method: Methods.GET,
    fieldName: "getClaimUser",
    manual: true,
  });

  const getClaims = useCallback(() => {
    if (!user) {
      handleBannerMessage(TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
      return;
    }
    return getApprovedClaim({
      filter:
        searchData && ccgFilterData
          ? {
              status: CLAIM_STATUS.approved,
              searchValue: searchData.searchString,
              claimOrganisationId: ccgFilterData.key,
            }
          : searchData
          ? {
              status: CLAIM_STATUS.approved,
              searchValue: searchData.searchString,
            }
          : ccgFilterData
          ? {
              status: CLAIM_STATUS.approved,
              claimOrganisationId: ccgFilterData.key,
            }
          : {
              status: CLAIM_STATUS.approved,
            },
    });
  }, [ccgFilterData, getApprovedClaim, handleBannerMessage, searchData, user]);

  useEffect(() => {
    if (user && user.username) {
      getUserData({
        id: user.username as string,
      });
    }
  }, [getUserData, user]);

  const userPermissions = useMemo(
    () => ({
      claimApprover: claimUserData?.claimApprover ?? false,
      claimPayer: claimUserData?.claimPayer ?? false,
    }),
    [claimUserData]
  );

  const ccgList = useMemo(
    () =>
      ccgData?.map((item) => {
        return {
          key: item.id,
          value: item.name,
        };
      }) ?? [],
    [ccgData]
  );

  const markAsPaid = useCallback(() => {
    if (!user) {
      handleBannerMessage(TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
      return;
    }
    setLoadingState(LOADING_STATES.PAY);
    handleConfirmation(
      MESSAGES.PAID_CLAIMS.replace(
        "{count}",
        `${
          selectedRowIds.length === 1
            ? selectedRowIds.length + " claim"
            : selectedRowIds.length + " claims"
        }`
      ),
      () => {
        setRowData((data) =>
          data.filter((val) => !selectedRowIds.includes(val.id))
        );
        updateClaim({
          input: {
            id: selectedRowIds,
            notes: `${CLAIM_NOTES.CLAIM_MARKED_AS_PAID}@${
              user.attributes.name ?? "N/A"
            }@${dayjs().format()}`,
            status: CLAIM_STATUS.paid,
          },
        })
          .then((data) => {
            if (data && data.length > 0) {
              handleBannerMessage(
                "success",
                `Claim marked as paid by ${user.attributes.name}`
              );
              setTimeout(() => {
                getClaims();
              }, EVENTUAL_CONSISTENCY_TIMEOUT);
            } else {
              handleBannerMessage(
                TYPE_ERROR,
                MESSAGES.UNEXPECTED_ERROR_MESSAGE
              );
            }
          })
          .catch((error) => {
            handleBannerMessage(TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
            Sentry.captureException(
              "Claims > approved, mark as paid error.",
              error
            );
          })
          .finally(finishLoading);
      },
      selectedRowIds.length === 1 ? "Mark as Paid" : "Mark as Paid",
      selectedRowIds.length === 1 ? "MARK AS PAID" : "MARK AS PAID",
      "CANCEL"
    );
  }, [
    finishLoading,
    getClaims,
    handleBannerMessage,
    handleConfirmation,
    selectedRowIds,
    setLoadingState,
    updateClaim,
    user,
  ]);

  useEffect(() => {
    if (approvedClaimData && !approvedClaimLoading) {
      setRowData(approvedClaimData?.map(mapTableData));
    } else {
      setRowData([]);
    }
    return () => {
      setRowData([]);
    };
  }, [approvedClaimData, approvedClaimLoading]);

  // Get approved Claim
  useEffect(() => {
    getClaims();
  }, [getClaims]);

  useEffect(() => {
    if (user) {
      getCcg({
        id: user.username,
      });
    }
  }, [getCcg, user]);

  useEffect(() => {
    if (dateRange) {
      getDateRangeData();
    }
  }, [dateRange, getDateRangeData]);

  useEffect(() => {
    if (
      updateClaimError ||
      ccgError ||
      dateRangeDataError ||
      approvedClaimError ||
      claimUserDataError
    ) {
      handleBannerMessage(TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
    }
  }, [
    claimUserDataError,
    updateClaimError,
    ccgError,
    dateRangeDataError,
    approvedClaimError,
    handleBannerMessage,
  ]);

  const ccg = useMemo(() => ccgData ?? [], [ccgData]);
  const featuresFromAllOrgs = useMemo(
    () =>
      ccg
        .map(
          (o) =>
            JSON.parse(
              o?.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
            ) as FeatureFlags
        )
        .reduce(
          (acc, curr) => ({
            ...acc,
            ...Object.entries(curr)
              .filter(([_, value]) => !!value)
              .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {}),
          }),
          {} as FeatureFlags
        ),
    [ccg]
  );

  const currentOrg = useMemo(
    () =>
      ccgFilterData
        ? ccg.find((o) => o.id === ccgFilterData?.key)
        : ({} as OrgUserResponse),
    [ccg, ccgFilterData]
  );
  const featuresFromCurrentOrg = useMemo(
    () =>
      !isEmpty(
        JSON.parse(
          currentOrg?.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
        ) as FeatureFlags
      )
        ? (JSON.parse(
            currentOrg?.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
          ) as FeatureFlags)
        : featuresFromAllOrgs,
    [currentOrg?.featureFlags, featuresFromAllOrgs]
  );

  useEffect(() => {
    if (dateRangeData) {
      setCsvData(mapCSVData(dateRangeData));
    }
  }, [dateRangeData]);
  const setSearchDataValue = useCallback(
    (val: FilterData) => {
      setSearchData(val);
    },
    [setSearchData]
  );

  useEffect(() => {
    if (location?.search.length > 0) {
      setClickedRowId(decodeURIComponent(location?.search.substring(1)));
      setShowDetails(true);
      navigate(location.pathname, {
        state: { isSameMenu: false, isInDetailsPage: true },
      });
    }
  }, [location.pathname, location?.search, navigate]);

  return (
    <>
      {showDetails ? (
        <ApprovedDetails
          rowId={clickedRowId}
          goBack={(from: "claimUpdating" | "backButton" | "sideMenuClick") => {
            switch (from) {
              case "sideMenuClick":
              case "backButton":
                getClaims();
                break;
              case "claimUpdating":
                setTimeout(() => {
                  getClaims();
                }, EVENTUAL_CONSISTENCY_TIMEOUT);
                break;
              default:
                break;
            }

            setShowDetails(false);
          }}
        />
      ) : (
        <Grid
          m={1}
          p={2}
          component={Card}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderRadius: 1,
            minHeight: window.innerHeight - 85,
          }}
        >
          <Grid container m={0}>
            <Grid container spacing={3} sx={{ height: "fit-content" }}>
              <Grid item xs={12}>
                <ProDataGrid
                  title="Approved"
                  rows={rowData}
                  endpointsLoading={loadingState}
                  columns={tableColumnList(
                    featuresFromAllOrgs,
                    featuresFromCurrentOrg,
                    ccgFilterData ? ccgFilterData.key : undefined,
                    "approved"
                  )}
                  ccgList={ccgList}
                  filterData={searchData}
                  filterCcgData={ccgFilterData}
                  csvData={csvData}
                  selectedCsvData={selectedCsvData}
                  dateRangeLoading={dateRangeDataLoading}
                  button={{
                    ...(userPermissions.claimPayer && {
                      markPaid: {
                        id: "mark_paid",
                        icon: <PaidIcon />,
                        loading: updateClaimLoading,
                      },
                    }),
                  }}
                  buttonId={(val) => {
                    switch (val) {
                      case "mark_paid":
                        userPermissions.claimPayer && markAsPaid();
                        break;
                      default:
                        break;
                    }
                  }}
                  dateRange={(val) => {
                    setDateRange(val);
                  }}
                  loading={approvedClaimLoading}
                  ccgLoading={ccgLoading}
                  refetchData={() => {
                    setSearchData(null);
                  }}
                  debouncedSearchString={setSearchDataValue}
                  ccgValue={(val) => {
                    setCcgFilterData(val);
                  }}
                  dataError={!approvedClaimData && !approvedClaimLoading}
                  selectedRows={(rowIds) => {
                    setSelectedRowIds(rowIds);
                    if (approvedClaimData) {
                      setSelectedCsvData(mapCSVData(approvedClaimData, rowIds));
                    }
                  }}
                  onClickRowData={(event) => {
                    setClickedRowId(event.id);
                    setShowDetails(true);
                    navigate(location.pathname, {
                      state: { isSameMenu: false, isInDetailsPage: true },
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default memo(Approved);
