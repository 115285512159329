import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
// Hooks
import ProDataGrid from "components/proDataGrid/ProDataGrid";
import { Card, Grid } from "@mui/material";
import MyClaimsDetails from "./MyClaimsDetails";
import { useAPI, useAuth, useLoading, usePopups } from "hooks";
import * as MESSAGES from "common/constants/messages";
import ApprovedIcon from "@mui/icons-material/Check";
import dayjs, { Dayjs } from "dayjs";
import { EVENTUAL_CONSISTENCY_TIMEOUT } from "common/constants/common";
import UnassignedIcon from "@mui/icons-material/Inbox";
import * as COMMON from "common/constants/common";
import PauseIcon from "@mui/icons-material/Pause";
import {
  mapCSVData,
  mapTableData,
  tableColumnList,
} from "utils/commonFunctions";
import DialogList from "components/dialogList";
import { uniq } from "lodash";
import {
  CcgUpdateClaimInput,
  Claim,
  ModelClaimFilterInputNew,
  ModelListClaimUsersFilterInput,
  OrgUserResponse,
  User,
} from "@s12solutions/types";
import { LOADING_STATES } from "common/types/loading";
import { INITIAL_FEATURE_FLAG_VALUES } from "common/constants/initialValues";
import {
  ColumnData,
  CSVData,
  CSVHeader,
  FeatureFlags,
  FilterData,
  TypeClaim,
} from "common/types/commonTypes";
import { CLAIM_STATUS } from "common/constants/options";
import { DateRange } from "@mui/x-date-pickers-pro";
import { isEmpty } from "lodash";
import { Methods } from "api";
import { CLAIM_NOTES } from "common/constants/notes";
import { AssignmentInd } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";

const MyClaims: FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { loadingState, setLoadingState, finishLoading } = useLoading();

  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [rowData, setRowData] = useState<TypeClaim[]>([]);
  const [clickedRowId, setClickedRowId] = useState<string>("");
  const [searchData, setSearchData] = useState<FilterData | null>(null);
  const [ccgFilterData, setCcgFilterData] = useState<ColumnData | null>(null);
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([
    dayjs().subtract(1, "day"),
    dayjs(),
  ]);
  const [csvData, setCsvData] = useState<[CSVHeader[], CSVData[]]>([[], []]);
  const [selectedCsvData, setSelectedCsvData] = useState<
    [CSVHeader[], CSVData[]]
  >([[], []]);
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const { handleConfirmation, handleBannerMessage } = usePopups();
  const [showUsers, setShowUsers] = useState<boolean>(false);
  const [userData, setUserData] = useState<OrgUserResponse[] | undefined>(
    undefined
  );

  // Queries
  const {
    data: myClaimData,
    loading: myClaimLoading,
    error: myClaimError,
    trigger: getMyClaims,
  } = useAPI<
    Claim[],
    {
      filter: ModelClaimFilterInputNew;
    }
  >({
    method: Methods.GET,
    fieldName: "listClaims",
    manual: true,
  });

  const {
    data: dateRangeData,
    loading: dateRangeDataLoading,
    error: dateRangeDataError,
    trigger: getDateRangeData,
  } = useAPI<
    Claim[],
    {
      filter: ModelClaimFilterInputNew;
    }
  >({
    method: Methods.GET,
    fieldName: "listClaims",
    manual: true,
  });

  const {
    loading: usersLoading,
    error: usersError,
    trigger: getUsers,
  } = useAPI<
    OrgUserResponse[],
    {
      filter: ModelListClaimUsersFilterInput;
    }
  >({
    method: Methods.GET,
    fieldName: "listClaimUsers",
    manual: true,
  });

  const {
    data: ccgData,
    loading: ccgLoading,
    error: ccgError,
    trigger: getCcg,
  } = useAPI<
    OrgUserResponse[],
    {
      id: string;
    }
  >({
    method: Methods.GET,
    fieldName: "getClaimUserOrgs",
    manual: true,
  });

  const {
    loading: updateClaimLoading,
    error: updateClaimError,
    trigger: updateClaim,
  } = useAPI<
    Claim[],
    {
      input: CcgUpdateClaimInput;
    }
  >({
    method: Methods.PUT,
    fieldName: "updateClaim",
  });

  const {
    data: claimUserData,
    error: claimUserDataError,
    trigger: getUserData,
  } = useAPI<
    User,
    {
      id: string;
    }
  >({
    method: Methods.GET,
    fieldName: "getClaimUser",
    manual: true,
  });

  useEffect(() => {
    if (user && user.username) {
      getUserData({
        id: user.username,
      });
    }
  }, [getUserData, user]);

  const userPermissions = useMemo(
    () => ({
      claimApprover: claimUserData?.claimApprover ?? false,
      claimPayer: claimUserData?.claimPayer ?? false,
    }),
    [claimUserData]
  );

  const getClaims = useCallback(() => {
    if (!user) {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
      return;
    }
    return getMyClaims({
      filter:
        searchData && ccgFilterData
          ? {
            status: CLAIM_STATUS.underReview,
            assigneeId: {
              eq: user.username,
            },
            searchValue: searchData.searchString,
            claimOrganisationId: ccgFilterData.key,
          }
          : searchData
            ? {
              status: CLAIM_STATUS.underReview,
              assigneeId: {
                eq: user.username,
              },
              searchValue: searchData.searchString,
            }
            : ccgFilterData
              ? {
                status: CLAIM_STATUS.underReview,
                assigneeId: {
                  eq: user.username,
                },
                claimOrganisationId: ccgFilterData.key,
              }
              : {
                status: CLAIM_STATUS.underReview,
                assigneeId: {
                  eq: user.username,
                },
              },
    });
  }, [ccgFilterData, getMyClaims, handleBannerMessage, searchData, user]);

  const unAssign = useCallback(() => {
    if (!user) {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
      return;
    }
    handleConfirmation(
      MESSAGES.UNASSIGN_CLAIMS.replace(
        "{count}",
        `${selectedRowIds.length === 1
          ? selectedRowIds.length + " claim"
          : selectedRowIds.length + " claims"
        }`
      ),
      () => {
        setRowData((data) =>
          data.filter((val) => !selectedRowIds.includes(val.id))
        );
        setLoadingState(LOADING_STATES.UNASSIGN);
        updateClaim({
          input: {
            id: selectedRowIds,
            assigneeId: "!!!",
            assigneeName: "!!!",
            notes: `${CLAIM_NOTES.CLAIM_UNASSIGNED}@${user.attributes.name
              }@${dayjs().format()}`,
            status: CLAIM_STATUS.underReview,
          },
        })
          .then((data) => {
            if (data && data.length > 0) {
              setTimeout(() => {
                getClaims();
              }, EVENTUAL_CONSISTENCY_TIMEOUT);
              handleBannerMessage(
                COMMON.TYPE_SUCCESS,
                MESSAGES.CLAIM_UNASSIGNED
              );
            } else {
              handleBannerMessage(
                COMMON.TYPE_ERROR,
                MESSAGES.UNEXPECTED_ERROR_MESSAGE
              );
            }
          })
          .catch(() => {
            handleBannerMessage(
              COMMON.TYPE_ERROR,
              MESSAGES.UNEXPECTED_ERROR_MESSAGE
            );
          })
          .finally(finishLoading);
      },
      "Unassign Claim",
      selectedRowIds.length === 1 ? "UNASSIGN CLAIM" : "UNASSIGN CLAIMS",
      "CANCEL"
    );
  }, [
    finishLoading,
    getClaims,
    handleBannerMessage,
    handleConfirmation,
    selectedRowIds,
    setLoadingState,
    updateClaim,
    user,
  ]);

  const ccgList = useMemo(
    () =>
      ccgData?.map((item) => {
        return {
          key: item.id,
          value: item.name,
        };
      }) ?? [],
    [ccgData]
  );

  const approve = useCallback(() => {
    if (!user) {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
      return;
    }
    handleConfirmation(
      MESSAGES.APPROVE_CLAIMS,
      () => {
        setRowData((data) =>
          data.filter((val) => !selectedRowIds.includes(val.id))
        );
        setLoadingState(LOADING_STATES.APPROVE);
        updateClaim({
          input: {
            id: selectedRowIds,
            notes: `${CLAIM_NOTES.CLAIM_APPROVED}@${user.attributes.name ?? "N/A"
              }@${dayjs().format()}`,
            status: CLAIM_STATUS.approved,
          },
        })
          .then((data) => {
            if (data && data.length > 0) {
              handleBannerMessage(
                "success",
                `Claim/s approved by ${user.attributes.name}`
              );
              setTimeout(() => {
                getClaims();
              }, EVENTUAL_CONSISTENCY_TIMEOUT);
            } else {
              handleBannerMessage(
                COMMON.TYPE_ERROR,
                MESSAGES.UNEXPECTED_ERROR_MESSAGE
              );
            }
          })
          .catch(() => {
            handleBannerMessage(
              COMMON.TYPE_ERROR,
              MESSAGES.UNEXPECTED_ERROR_MESSAGE
            );
          })
          .finally(finishLoading);
      },
      selectedRowIds.length === 1 ? "Approve Claim" : "Approve Claims",
      selectedRowIds.length === 1 ? "APPROVE CLAIM" : "APPROVE CLAIMS",
      "CANCEL"
    );
  }, [
    user,
    handleConfirmation,
    selectedRowIds,
    handleBannerMessage,
    setLoadingState,
    updateClaim,
    finishLoading,
    getClaims,
  ]);

  useEffect(() => {
    if (myClaimData && !myClaimLoading) {
      setRowData(myClaimData?.map(mapTableData));
    } else {
      setRowData([]);
    }
    return () => {
      setRowData([]);
    };
  }, [myClaimData, myClaimLoading]);

  useEffect(() => {
    getClaims();
  }, [getClaims]);

  useEffect(() => {
    if (user) {
      getCcg({
        id: user.username,
      });
    }
  }, [getCcg, user]);

  useEffect(() => {
    if (dateRange && user) {
      getDateRangeData({
        filter: {
          status: CLAIM_STATUS.underReview,
          assigneeId: {
            eq: user.username,
          },
          receivedDateFrom:
            dateRange && dateRange[0] ? dateRange[0].format("YYYY-MM-DD") : "",
          receivedDateTo:
            dateRange && dateRange[1] ? dateRange[1].format("YYYY-MM-DD") : "",
        },
      });
    }
  }, [dateRange, getDateRangeData, user]);

  useEffect(() => {
    if (
      myClaimError ||
      updateClaimError ||
      ccgError ||
      dateRangeDataError ||
      usersError ||
      claimUserDataError
    ) {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
    }
  }, [
    ccgError,
    claimUserDataError,
    dateRangeDataError,
    handleBannerMessage,
    myClaimError,
    updateClaimError,
    usersError,
  ]);

  const assignClaimToUser = useCallback(
    (
      userData: {
        id: string;
        name: string;
      } | null
    ) => {
      if (!userData) {
        return;
      }
      setRowData((data) =>
        data.filter((val) => !selectedRowIds.includes(val.id))
      );
      setLoadingState(LOADING_STATES.ASSIGN_TO_USER);
      updateClaim({
        input: {
          id: selectedRowIds,
          assigneeId: userData.id.toString(),
          assigneeName: userData.name,
          notes: `${CLAIM_NOTES.CLAIM_ASSIGNED}@${userData.name ?? "N/A"
            }@${dayjs().format()}`,
          status: CLAIM_STATUS.underReview,
        },
      })
        .then((data) => {
          if (data && data.length > 0) {
            setTimeout(() => {
              getClaims();
            }, EVENTUAL_CONSISTENCY_TIMEOUT);
            handleBannerMessage(COMMON.TYPE_SUCCESS, MESSAGES.CLAIM_ASSIGNED);
          } else {
            handleBannerMessage(
              COMMON.TYPE_ERROR,
              MESSAGES.UNEXPECTED_ERROR_MESSAGE
            );
          }
        })
        .catch(() => {
          handleBannerMessage(
            COMMON.TYPE_ERROR,
            MESSAGES.UNEXPECTED_ERROR_MESSAGE
          );
        })
        .finally(finishLoading);
    },
    [
      setLoadingState,
      updateClaim,
      selectedRowIds,
      finishLoading,
      handleBannerMessage,
      getClaims,
    ]
  );

  const ccg = useMemo(() => ccgData ?? [], [ccgData]);
  const featuresFromAllOrgs = useMemo(
    () =>
      ccg
        .map(
          (o) =>
            JSON.parse(
              o?.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
            ) as FeatureFlags
        )
        .reduce(
          (acc, curr) => ({
            ...acc,
            ...Object.entries(curr)
              .filter(([_, value]) => !!value)
              .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {}),
          }),
          {} as FeatureFlags
        ),
    [ccg]
  );

  const currentOrg = useMemo(
    () =>
      ccgFilterData
        ? ccg.find((o) => o.id === ccgFilterData?.key)
        : ({} as OrgUserResponse),
    [ccg, ccgFilterData]
  );
  const featuresFromCurrentOrg = useMemo(
    () =>
      !isEmpty(
        JSON.parse(
          currentOrg?.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
        ) as FeatureFlags
      )
        ? (JSON.parse(
          currentOrg?.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
        ) as FeatureFlags)
        : featuresFromAllOrgs,
    [currentOrg?.featureFlags, featuresFromAllOrgs]
  );

  useEffect(() => {
    if (dateRangeData) {
      setCsvData(mapCSVData(dateRangeData));
    }
  }, [dateRangeData]);

  const setSearchDataValue = useCallback(
    (val: FilterData) => {
      setSearchData(val);
    },
    [setSearchData]
  );

  useEffect(() => {
    if (location?.search.length > 0) {
      setClickedRowId(decodeURIComponent(location?.search.substring(1)));
      setShowDetails(true);
      navigate(location.pathname, {
        state: { isSameMenu: false, isInDetailsPage: true },
      });
    }
  }, [location.pathname, location?.search, navigate]);

  return (
    <>
      {showDetails ? (
        <MyClaimsDetails
          rowId={clickedRowId}
          goBack={(from: "claimUpdating" | "backButton" | "sideMenuClick") => {
            switch (from) {
              case "sideMenuClick":
              case "backButton":
                getClaims();
                break;
              case "claimUpdating":
                setTimeout(() => {
                  getClaims();
                }, EVENTUAL_CONSISTENCY_TIMEOUT);
                break;
              default:
                break;
            }

            setShowDetails(false);
          }}
          ccgId={rowData.find((val) => val.id === clickedRowId)?.ccgId}
        />
      ) : (
        <Grid
          m={1}
          p={2}
          component={Card}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderRadius: 1,
            minHeight: window.innerHeight - 85,
          }}
        >
          <Grid container m={0}>
            <Grid container spacing={3} sx={{ height: "fit-content" }}>
              {/* {assignClaimsLoading && <LoadingCircle />} */}
              <DialogList
                openDialog={showUsers}
                closeDialog={() => {
                  setShowUsers(false);
                }}
                selectedRowIds={selectedRowIds.length}
                listData={userData}
                handleUser={(user) => {
                  setShowUsers(false);
                  assignClaimToUser(user);
                }}
                loadingUser={usersLoading}
                loading={updateClaimLoading}
              />
              <Grid item xs={12}>
                <ProDataGrid
                  title="My Claims"
                  rows={rowData}
                  columns={tableColumnList(
                    featuresFromAllOrgs,
                    featuresFromCurrentOrg,
                    ccgFilterData ? ccgFilterData.key : undefined
                  )}
                  ccgList={ccgList}
                  filterData={searchData}
                  filterCcgData={ccgFilterData}
                  csvData={csvData}
                  selectedCsvData={selectedCsvData}
                  dateRangeLoading={dateRangeDataLoading}
                  button={{
                    ...(userPermissions.claimApprover && {
                      approve: {
                        id: "approve",
                        icon: <ApprovedIcon />,
                        loading: loadingState === LOADING_STATES.APPROVE,
                      },
                    }),
                    assignClaims: {
                      id: "assign_claim",
                      icon: <AssignmentInd />,
                      loading:
                        usersLoading ||
                        loadingState === LOADING_STATES.ASSIGN_TO_USER,
                    },
                    unassignClaims: {
                      id: "un_assign",
                      icon: <UnassignedIcon />,
                      loading: loadingState === LOADING_STATES.UNASSIGN,
                    },
                    putOnHoldClaims: {
                      id: "put-on-hold-claim",
                      icon: <PauseIcon />,
                      loading: loadingState === LOADING_STATES.ON_HOLD,
                    },
                  }}
                  buttonId={(val) => {
                    switch (val) {
                      case "approve":
                        !!userPermissions.claimApprover && approve();
                        break;
                      case "un_assign":
                        unAssign();
                        break;
                      case "assign_claim":
                        if (selectedRowIds.length > 0 && user) {
                          getUsers({
                            filter: {
                              loggedInUserId: user.username,
                              selectedClaimOrganisations: uniq(
                                rowData
                                  .filter((val) =>
                                    selectedRowIds.some((v) => v === val.id)
                                  )
                                  .map((val) => val.ccgId)
                              ),
                            },
                          })
                            .then((data) => {
                              setShowUsers(true);
                              setUserData(data);
                            })
                            .catch(() => {
                              handleBannerMessage(
                                COMMON.TYPE_ERROR,
                                MESSAGES.UNEXPECTED_ERROR_MESSAGE
                              );
                            });
                        }
                        break;
                      case "put-on-hold-claim":
                        if (user) {
                          handleConfirmation(
                            MESSAGES.PUT_CLAIMS_ON_HOLD.replace(
                              "{count}",
                              `${selectedRowIds.length === 1
                                ? selectedRowIds.length + " claim"
                                : selectedRowIds.length + " claims"
                              }`
                            ),
                            (reason?: string) => {
                              setRowData((data) =>
                                data.filter(
                                  (val) => !selectedRowIds.includes(val.id)
                                )
                              );
                              setLoadingState(LOADING_STATES.ON_HOLD);
                              updateClaim({
                                input: {
                                  id: selectedRowIds,
                                  notes: reason
                                    ? `${CLAIM_NOTES.CLAIM_PUT_TO_ON_HOLD}@${user.attributes.name ?? "N/A"
                                    }@${dayjs().format()}@${reason}`
                                    : `${CLAIM_NOTES.CLAIM_PUT_TO_ON_HOLD}@${user.attributes.name ?? "N/A"
                                    }@${dayjs().format()}`,
                                  status: "on_hold",
                                },
                              })
                                .then((data) => {
                                  if (data && data.length > 0) {
                                    handleBannerMessage(
                                      "success",
                                      `Claim put to on hold by ${user.attributes.name}`
                                    );
                                    setTimeout(() => {
                                      getClaims();
                                    }, EVENTUAL_CONSISTENCY_TIMEOUT);
                                  } else {
                                    handleBannerMessage(
                                      COMMON.TYPE_ERROR,
                                      MESSAGES.UNEXPECTED_ERROR_MESSAGE
                                    );
                                  }
                                })
                                .catch(() => {
                                  handleBannerMessage(
                                    COMMON.TYPE_ERROR,
                                    MESSAGES.UNEXPECTED_ERROR_MESSAGE
                                  );
                                })
                                .finally(finishLoading);
                            },
                            selectedRowIds.length === 1
                              ? "Put On Hold"
                              : "Put On Hold",
                            selectedRowIds.length === 1
                              ? "PUT ON HOLD"
                              : "PUT ON HOLD",
                            "CANCEL",
                            true
                          );
                        }
                        break;
                      default:
                        break;
                    }
                  }}
                  dateRange={(val) => {
                    setDateRange(val);
                  }}
                  dataError={!myClaimData && !myClaimLoading}
                  loading={myClaimLoading}
                  ccgLoading={ccgLoading}
                  refetchData={() => {
                    setSearchData(null);
                  }}
                  debouncedSearchString={setSearchDataValue}
                  ccgValue={(val) => {
                    setCcgFilterData(val);
                  }}
                  selectedRows={(rowIds) => {
                    setSelectedRowIds(rowIds);
                    if (myClaimData) {
                      setSelectedCsvData(mapCSVData(myClaimData, rowIds));
                    }
                  }}
                  onClickRowData={(event) => {
                    setClickedRowId(event.id);
                    setShowDetails(true);
                    navigate(location.pathname, {
                      state: { isSameMenu: false, isInDetailsPage: true },
                    });
                  }}
                  endpointsLoading={loadingState}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default memo(MyClaims);
