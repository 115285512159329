// Definition and load of all routes for the system
import React from "react";
import { Routes, Route } from "react-router-dom";

// Auth
import { useAuth } from "hooks";
// Pages
import Homepage from "pages/Homepage";
import Approved from "pages/Approved";
import Archived from "pages/Archived";
import MyClaims from "pages/MyClaims";
import Paid from "pages/Paid";
import Rejected from "pages/Rejected";
import Support, {
  ClaimExplained,
  EULA,
  PrivacyPolicy,
  SupportExplained,
} from "pages/Support";
import TeamClaims from "pages/TeamClaims";
import Unassigned from "pages/Unassigned";
// import UnassignedDetails from "pages/Unassigned/UnassignedDetails";
import Info from "pages/Info";

// Custom
import PrivateRoute from "./PrivateRoute";
import {
  ROOT,
  HOME,
  APPROVED,
  PAID,
  MY_CLAIMS,
  REJECTED,
  SUPPORT,
  TEAM_CLAIMS,
  UNASSIGNED,
  // UNASSIGNED_DETAILS,
  INFO,
  PRIVACY_POLICY,
  TERMS_OF_USE,
  SUPPORT_EXPLAINED,
  CLAIM_EXPLAINED,
  ON_HOLD,
  ARCHIVED,
} from "./RouteConstants";
import OnHold from "pages/On-Hold";
const ApplicationRoutes: React.FC = () => {
  const { user } = useAuth();
  const isAuth = !!user; // convert user object to boolean as private route expects a boolean prop

  return (
    <div>
      <Routes>
        {/* Authenticated routes */}
        <Route path={ROOT} element={<PrivateRoute access={isAuth} />}>
          <Route path={HOME} element={<Homepage />} />

          <Route path={APPROVED} element={<Approved />} />

          <Route path={ON_HOLD} element={<OnHold />} />
          <Route path={MY_CLAIMS} element={<MyClaims />} />

          <Route path={PAID} element={<Paid />} />

          <Route path={REJECTED} element={<Rejected />} />
          <Route path={SUPPORT} element={<Support />} />
          <Route path={PRIVACY_POLICY} element={<PrivacyPolicy />} />
          <Route path={TERMS_OF_USE} element={<EULA />} />
          <Route path={SUPPORT_EXPLAINED} element={<SupportExplained />} />
          <Route path={CLAIM_EXPLAINED} element={<ClaimExplained />} />
          <Route path={TEAM_CLAIMS} element={<TeamClaims />} />
          <Route path={UNASSIGNED} element={<Unassigned />} />
          <Route path={INFO} element={<Info />} />
          <Route path={ARCHIVED} element={<Archived />} />
        </Route>
      </Routes>
    </div>
  );
};

export default React.memo(ApplicationRoutes);
